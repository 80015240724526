<template>
  <div>
    <v-row no-gutters class="mt-5">
      <v-col cols="12">
        <v-img
          position
          class="shrink"
          contain
          src="@/assets/svg/logoPietaMenu.svg"
        />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <v-menu
          v-for="(item, index) in menuPietaItems"
          :key="index"
          open-on-hover
          origin="left center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-card
              :class="
                item.hover
                  ? 'menu-pieta-style-hover my-5 py-2'
                  : 'menu-pieta-style my-5 py-2 d-flex align-center justify-center'
              "
              @mouseenter="item.hover = true"
              @mouseleave="item.hover = false"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="18" class="icon-menu">{{ item.icon }}</v-icon>
            </v-card>
          </template>
          <v-card
            @mouseenter="item.hover = true"
            @mouseleave="item.hover = false"
            elevation="2"
            class="menu-pieta-expanded d-flex align-center"
          >
            <v-row no-gutters class="my-2 mx-8">
              <v-col cols="1" class="ml-n5" align-self="center" align="center">
                <v-icon size="30" class="icon-menu">{{ item.icon }}</v-icon>
              </v-col>
              <v-col cols="11" align-self="center" class="ml-3">
                <v-row no-gutters>
                  <v-col cols="12" align="start">
                    <a class="title-style" :href="item.link" target="_blank">
                      {{ item.title }}
                      <v-icon class="pt-n2" size="12">
                        {{ ICONS.ARROW_CHEVRON_RIGHT }}
                      </v-icon>
                    </a>
                  </v-col>
                </v-row>
                <v-row no-gutters class="message-style">
                  {{ item.message }}
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ICONS } from "@/utils";
export default {
  name: "MenuPieta",
  data() {
    return {
      ICONS,
      menuPietaItems: [
        {
          icon: ICONS.PHOTOVOLTAIC_KIT_SEARCH,
          title: "Buscador de kits fotovoltaicos",
          message: "Busca facilitada de equipamentos fotovoltaicos",
          link: "https://lojapieta.com.br/",
          hover: false,
        },
        {
          icon: ICONS.FINANCING,
          title: "Financiamento",
          message:
            "Melhores taxas de mercado e atendimento exclusivo especializado",
          link: "https://financiamento.clubepieta.com.br/",
          hover: false,
        },
        {
          icon: ICONS.POWER_PLANT_OUTLINE,
          title: "Projetos e homologação",
          message:
            "Solicite a elaboração e homologação de projetos fotovoltaicos",
          link: "https://clubepieta.com.br/projetos",
          hover: false,
        },
        {
          icon: ICONS.FILE,
          title: "Propostas",
          message: "Gere propostas para seus clientes em um só lugar",
          link: "https://www.piev.com.br/",
          hover: false,
        },
        {
          icon: ICONS.CAPACITATION,
          title: "Capacitação",
          message: "Conteúdos exclusivos do setor fotovoltaico",
          link: "https://www.youtube.com/@Pietatech/playlists",
          hover: false,
        },
        {
          icon: ICONS.CONSULTANCY,
          title: "Consultoria",
          message: "Receba suporte de engenheiros especializados",
          link: "https://api.whatsapp.com/send/?phone=554892079609&text&type=phone_number&app_absent=0",
          hover: false,
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/soft-ui-dashboard/piem_variables.scss";
.icon-menu {
  background-color: $color3 !important;
}

.menu-pieta-style {
  background-color: $color3 !important;
  box-shadow: 2px 4px 7px 0px rgba(0, 0, 0, 6) !important;
}

.menu-pieta-style-hover {
  background-color: transparent !important;
  opacity: 0;
}

.menu-pieta-expanded {
  background-color: $color3 !important;
  z-index: 3;
}

.title-style {
  font-weight: 600;
  font-size: 12px;
  line-height: 14.63px;
  color: $color2 !important;
  font-family: "Montserrat";
  text-decoration: none;
}

.message-style {
  font-weight: 400;
  font-size: 12px;
  line-height: 14.63px;
  color: $color2 !important;
  font-family: "Montserrat";
  white-space: nowrap;
}
</style>
